export const nextCampaignStartDate = new Date('2024/10/17 00:00:00')

export type RemainingTime = {
  milliSeconds: number
  days: number
  hours: number
  minutes: number
  seconds: number
}
export const remainingTimeForCountdown = (): RemainingTime => {
  const milliSeconds = Math.floor(
    (nextCampaignStartDate.getTime() - new Date().getTime()) / 1000
  )
  const days = Math.floor(milliSeconds / (60 * 60 * 24))
  const hours = Math.floor((milliSeconds / (60 * 60)) % 24)
  const minutes = Math.floor((milliSeconds / 60) % 60)
  const seconds = milliSeconds % 60
  return { milliSeconds, days, hours, minutes, seconds }
}
