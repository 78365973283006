import { useState } from 'react'

import styles from './index.module.scss'

import participantBenefitPc from '../../public/participant_benefit_202408_pc.png'
import participantBenefitSp from '../../public/participant_benefit_202408_sp.png'

import currentCampaignPc from '../../public/campaign_banner_otl_202410_pc.png'
import currentCampaignPcDetail from '../../public/campaign_banner_detail_otl_202410_pc.png'

import currentCampaignSp from '../../public/campaign_banner_otl_202410_sp.png'
import currentCampaignSpDetail from '../../public/campaign_banner_detail_otl_202410_sp.png'

import nextCampaignPc from '../../public/campaign_banner_otl_202410_pc.png'
import nextCampaignPcDetail from '../../public/campaign_banner_detail_otl_202410_pc.png'

import nextCampaignSp from '../../public/campaign_banner_otl_202410_sp.png'
import nextCampaignSpDetail from '../../public/campaign_banner_detail_otl_202410_sp.png'

import { AwardContentReskilling } from '../AwardContentReskilling'

type Props = {
  isNextCampaignStarted: boolean
  isPc: boolean
  showReskilling: boolean
}

export const AwardContentOtl = ({
  isNextCampaignStarted,
  isPc,
  showReskilling,
}: Props) => {
  const [showCampaignDetail, setShowCampaignDetail] = useState<boolean>(false)

  const toggleCampaignDetail = () => {
    setShowCampaignDetail(!showCampaignDetail)
  }

  return (
    <div className={styles.container}>
      {showReskilling ? (
        <AwardContentReskilling isPc={isPc} />
      ) : (
        <img
          src={isPc ? participantBenefitPc.src : participantBenefitSp.src}
          alt=""
        />
      )}
      <div className={styles.campaignContainer}>
        <div
          className={`${
            isNextCampaignStarted
              ? styles.nextCpShowDetailToggle
              : styles.currentCpShowDetailToggle
          } ${
            showCampaignDetail
              ? isNextCampaignStarted
                ? styles.nextCpOpen
                : styles.currentCpOpen
              : isNextCampaignStarted
              ? styles.nextCpClose
              : styles.currentCpClose
          }`}
          onClick={toggleCampaignDetail}
        ></div>
        {showCampaignDetail ? (
          <img
            src={
              isNextCampaignStarted
                ? isPc
                  ? nextCampaignPcDetail.src
                  : nextCampaignSpDetail.src
                : isPc
                ? currentCampaignPcDetail.src
                : currentCampaignSpDetail.src
            }
            alt=""
          />
        ) : (
          <img
            src={
              isNextCampaignStarted
                ? isPc
                  ? nextCampaignPc.src
                  : nextCampaignSp.src
                : isPc
                ? currentCampaignPc.src
                : currentCampaignSp.src
            }
            alt=""
          />
        )}
      </div>
    </div>
  )
}
